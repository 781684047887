<template>
    <div>
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsc">
                <span class="a-c-master a-fw-700 a-fs-16">预估电量消耗（单位：kwh）</span>
                <el-tooltip placement="top">
                    <div slot="content">
                        所选周期范围内设备统计的预估电量消耗<br />
                        我方缴纳电量：已建立电费项目，且由我方付款的<br />
                        他方缴纳电量：已建立电费项目，且由他方付款的<br />
                        未知缴纳方电量：未建立电费项目的
                    </div>
                    <i class="el-icon-question a-c-blue" ></i>
                </el-tooltip>
            </div>
            <jd-other-date-range
                ref="dateRange"
                style="padding: 0 !important"
                @change="dateChange"
                :pickerOptions="pickerOptions" 
                :timeType.sync="timeType"
                valueFormat="yyyy-MM-dd HH:mm:ss"
                :minDate.sync="startDate" 
                :maxDate.sync="endDate"
                thisMonth>
            </jd-other-date-range>
        </div>
        <div class="a-flex-rfsc a-mt-10">
            <div class="a-flex-cfsfs" style="margin-right:107px">
                <span class="a-fs-14 a-c-normal">搜索范围内预估电量消耗</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ totalEnergy }}</span>
                    <span class="a-fs-24 a-c-master">kwh</span>
                </div>
            </div>
        </div>
        <div id="estimateElectric" class="mychart " v-loading='showLoading'></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                myChartContxt: null,
                choiceDate: '',
                choiceMonth: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        this.choiceMonth = minDate
                        if (maxDate) {
                            this.choiceDate = ''
                            this.choiceMonth = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            if(this.timeType == 0){
                                let one = 31 * 24 * 3600 * 1000
                                const minTime = this.choiceDate - one
                                const maxTime = this.choiceDate + one
                                return time.getTime() < minTime || time.getTime() > maxTime
                            }else if(this.timeType == 1){
                                let minMonth = this.choiceMonth.getMonth(),
                                lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                                nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                                return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                            }
                        }else{
                            return ''
                        }
                    },
                },
                timeType: 1,
                startDate: '',
                endDate: '',
                totalEnergy: 0,
                showLoading: false
            }
        },
        created () {
            this.startDate = this.$getDay.getSomeMonthDays(this.$getDay.getTodayBeforeMonth(11)).starttime + ' 00:00:00'
			this.endDate = this.$getDay.getSomeMonthDays(new Date()).endtime + ' 23:59:59'
        },
        mounted () {
            this.$refs['dateRange'].type = 'monthrange'
            this.getChartData()
        },
        props: {
            params: {
                default: ''
            }
        },
        watch:{
            params:{
                deep: true,
                handler (val) {
                    this.getChartData()
                }
            },
        },
        methods:{
            drawChart (xAxis=[], seriesData1=[], seriesData2=[], seriesData3=[]) { 
                var chartDom = document.getElementById('estimateElectric');
                var option;
                option = {
                    color: ["#3CA272","#EE6666","#73C0DE","#FAC858"],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: (value, index)=> {
                                if(this.timeType==0){
                                    let arr = value.split('-')
                                    return arr[1]+'-'+arr[2];
                                }
                                return value
                            }
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 'dataMax',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                        axisLabel: {
                            formatter: (value, index)=>{
                                if(value > 10000) {
                                    return Number((value / 10000).toFixed(2)) + '万'
                                }else {
                                    return value
                                }
                            }
                        }
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            startValue: 0,
                            endValue: 100
                        },
                    ],
                    series: [
                        {   
                            name: '他方缴纳电量',
                            data: seriesData1,
                            type: 'bar',
                            stack: 'total',
                            barMaxWidth: 20,
                            label: {
                                normal:{
                                    show:false,
                                }
                            },
                           
                        },
                        {   
                            name: '未知缴纳方电量',
                            data: seriesData3,
                            type: 'bar',
                            stack: 'total',
                            barMaxWidth: 20,
                            label: {
                                normal:{
                                    show:false,
                                }
                            },
                           
                        },
                        {
                            name: '我方缴纳电量',
                            data: seriesData2,
                            type: 'bar',
                            stack: 'total',
                            barMaxWidth: 20,
                            label: {
                                normal:{
                                    show:true,
                                    position: 'top',
                                    textStyle:{
                                        color: '#606366',
                                    },
                                    formatter: (params)=> {
                                        return this.$decimal.decimal_add(seriesData1[params.dataIndex], seriesData2[params.dataIndex], seriesData3[params.dataIndex])
                                    }
                                }
                            },
                            itemStyle: {
                                normal:{
                                    barBorderRadius: [4,4,0,0],
                                }
                            },
                        }
                    ]
                };
                if(!this.myChartContxt){
                    this.myChartContxt = this.$echarts.init(chartDom);
                }
                this.myChartContxt.clear()
                this.myChartContxt.setOption(option,true);
            },
            dateChange () {
                this.getChartData()
            },
            // 获取图表数据
            getChartData () {
                this.showLoading = true
                this.$Axios._post({
                    url: this.$Config.apiUrl.elePredictEnergyStat,
                    method: "post",
                    showLoading: false,
                    params: {
                        queryType: this.timeType,
						startDate: this.startDate,
						endDate: this.endDate,
						...this.params
                    }
                }).then(({data}) => {
                    this.showLoading = false
                    this.totalEnergy = data.totalEnergy
                    let dateArr = []

                    if(this.timeType == 0) {
                        dateArr = this.$getDay.enumerateDaysBetweenDates(this.startDate,this.endDate).map(item=>{
                            return {
                                day: item,
                                otherEnergy: 0, //他方缴纳电量
                                ourEnergy: 0,//我方缴纳电量
                                unknownEnergy: 0,//未知缴纳电量
                            }
                        })
                    }else if(this.timeType == 1) {
                        dateArr = this.$getDay.enumerateMonthsBetweenDates(this.startDate,this.endDate).map(item=>{
                            return {
                                day: item,
                                otherEnergy: 0, //他方缴纳电量
                                ourEnergy: 0,//我方缴纳电量
                                unknownEnergy: 0,//未知缴纳电量
                            }
                        })
                    }

                    data.list.map(item=>{
                        let targetIndex = dateArr.findIndex(res=>{
                            return res.day == item.date
                        })
                        if(targetIndex != -1) {
                            dateArr[targetIndex].otherEnergy = item.otherEnergy
                            dateArr[targetIndex].ourEnergy = item.ourEnergy
                            dateArr[targetIndex].unknownEnergy = item.unknownEnergy
                        }
                    })

                    let xAxis = []
                    let seriesData1 = []
                    let seriesData2 = []
                    let seriesData3 = []
                    dateArr.map(item=>{
                        xAxis.push(item.day)
                        seriesData1.push(item.otherEnergy || 0)
                        seriesData2.push(item.ourEnergy || 0)
                        seriesData3.push(item.unknownEnergy || 0)
                    })
                    this.drawChart(xAxis, seriesData1, seriesData2, seriesData3)
                }).catch(err=>{
                    console.log(err);
                    this.showLoading = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.tips2{
    width: 11px;
    height: 11px;
    background: #FFA900;
    border-radius: 3px;
}
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
.tooltips{
    background: #FFFFFF;
    box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);
    opacity: 0.96;
}
.tooltipsTitle{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606366;
    line-height: 27px;
    padding: 0 25px;
}
.tooltipsContent{
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    line-height: 32px;
}
</style>