<template>
    <div>
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsc">
                <span class="a-c-master a-fw-700 a-fs-16">未抄表项目Top20</span>
            </div>
        </div>
        <el-table :data="tabledata" class="a-mt-20">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column label="项目" prop="orderId">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.balance) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="历史已交电费" prop="operatorUserPhone">
                <template slot-scope="{ row }">
                    <span>{{ row.maxChargingMinutes }}分钟</span>
                </template>
            </el-table-column>
            <el-table-column label="预估电费总额" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="待交电费总额" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                tabledata: []
            }
        },
        created () {
            
        },
        watch:{
            params:{
                immediate: true,
                handler (val) {
                    
                }
            },
        },
        methods:{
            
        }
    }
</script>

<style lang="scss" scoped>
.tips2{
    width: 11px;
    height: 11px;
    background: #FFA900;
    border-radius: 3px;
}
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
.tooltips{
    background: #FFFFFF;
    box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);
    opacity: 0.96;
}
.tooltipsTitle{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606366;
    line-height: 27px;
    padding: 0 25px;
}
.tooltipsContent{
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    line-height: 32px;
}
</style>