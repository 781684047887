<template>
    <div class="app-body content a-flex-cfsfs">
        <div class="content-layout ">
            <div class="a-flex-rfsc">
                <span class="a-fw-700 a-flex-1">电费看板</span>
                <le-select-remote-search label="电费项目" v-model="form.projectId" :options="optionsElectric" placeholder="请选择电费项目(可搜索)" style="padding: 0 !important" />
                <le-select-remote-search label="站点" v-model="form.stationId" :options="optionsStation" placeholder="请选择站点(可搜索)" style="padding: 0 !important" />
            </div>
            <div class="a-flex-rfsc a-mt-25" v-loading='showLoading'>
                <div class="a-flex-cfsfs a-flex-1" >
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-normal">当月实付金额</span>
                        <el-tooltip placement="top">
                            <div slot="content">
                                当期实际已支付的电费总和，包括后付和预付的部分
                            </div>
                            <i class="el-icon-question a-c-blue" ></i>
                        </el-tooltip>
                    </div>
                    <div class="a-flex-rfsb a-mt-10 familyDin">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">{{ util.numFormat(statistics.currentMonthRealPay || 0) }}</span>
                        <span class="a-fs-14 a-c-blue">上月￥{{ util.numFormat(statistics.lastMonthRealPay || 0) }}</span>
                    </div>
                </div>
                <div class="a-flex-cfsfs a-flex-1" >
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-normal">当月估算电费</span>
                        <el-tooltip placement="top">
                            <div slot="content">
                                根据设备统计的当期电量，乘以项目单价算得的电费。<br />未创建电费项目的，或不需要我方付电费的项目不统计
                            </div>
                            <i class="el-icon-question a-c-blue" ></i>
                        </el-tooltip>
                    </div>
                    <div class="a-flex-rfsb a-mt-10 familyDin">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">{{ util.numFormat(statistics.currentMonthPredict || 0) }}</span>
                        <span class="a-fs-14 a-c-blue">上月￥{{ util.numFormat(statistics.lastMonthPredict || 0) }}</span>
                    </div>
                </div>
                <div class="a-flex-cfsfs a-flex-1"  v-if="false">
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-normal">提单待付</span>
                        <el-tooltip placement="top">
                            <div slot="content">
                                已创建有效的电费单，且尚未支付的费用总和
                            </div>
                            <i class="el-icon-question a-c-blue" ></i>
                        </el-tooltip>
                    </div>
                    <div class="a-flex-rfsb a-mt-10 familyDin">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">0</span>
                    </div>
                </div>
                <div class="a-flex-cfsfs a-flex-1" v-if="false">
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-normal">待抄表（预估）</span>
                        <el-tooltip placement="top">
                            <div slot="content">
                                估算电费总额-已抄表总额。<br />未创建电费项目的，或不需要我方付电费的项目不统计
                            </div>
                            <i class="el-icon-question a-c-blue" ></i>
                        </el-tooltip>
                    </div>
                    <div class="a-flex-rfsb a-mt-10 familyDin">
                        <span class="a-fs-24 a-c-master a-fw-700">￥</span>
                        <span class="a-fs-32 a-c-master a-fw-700">0</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="a-mt-16 a-flex-1 content-layout" style="align-items: stretch;">
            <charge-fee :params="form"></charge-fee>
            <estimate-electric class="a-mt-50"></estimate-electric>
            <actual-electric class="a-mt-50"></actual-electric>
            <estimate-cost-electric class="a-mt-50"></estimate-cost-electric>
            <actual-cost-electric class="a-mt-50"></actual-cost-electric>
            <!-- <not-meter-reading class="a-mt-36"></not-meter-reading> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ActualCostElectric from './data-panel-child/actual-cost-electric.vue';
import ActualElectric from './data-panel-child/actual-electric.vue';
import ChargeFee from './data-panel-child/charge-fee.vue'
import EstimateCostElectric from './data-panel-child/estimate-cost-electric.vue';
import EstimateElectric from './data-panel-child/estimate-electric.vue';
import NotMeterReading from './data-panel-child/not-meter-reading.vue';
import util from '@/utils/util'
export default {
    components: {
        ChargeFee,
        EstimateElectric,
        ActualElectric,
        EstimateCostElectric,
        ActualCostElectric,
        NotMeterReading
    },
    data () {
        return {
            util,
            form: {
                projectId: '',
				stationId: ''
            },
            statistics: {},
            optionsElectric: {
                url: this.$Config.apiUrl.electricFilter,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 100
            },
            optionsStation: {
                url: this.$Config.apiUrl.getStationList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "stationId",
                searchKey: "name",
                pageSize: 100
            },//站点数据
            showLoading: false
        }
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    watch: {
        form: {
            deep: true,
            handler (val) {
                this.handleGetEleInfo()
            }
        }
    },
    mounted () {
        this.handleGetEleInfo()
    },
    methods: {
        handleGetEleInfo () {
            this.showLoading = true
            this.$Axios._post({
                url: this.$Config.apiUrl.eleTitleStatInfo,
                showLoading: false,
                params: { 
                    queryType: 1,
					...this.form
                }
            }).then(({data}) => {
                this.showLoading = false
                this.statistics = Object.assign({}, data)
            }).catch(_=>{
                this.showLoading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content{
    margin-top: 16px;
    overflow-y: auto;
    padding-bottom: 32px;
    height: 100%;
    align-items: stretch;
    .content-layout{
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        .content-main-title{
            font-size: 14px;
            color: #333333;
            font-weight: 700;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
        .content-main-title::before{
            content: "";
            display: inline-block;
            width: 4px;
            height: 14px;
            border-radius: 4px;
            background: #007af0;
            margin-right: 8px;
        }
        .content-main-morebtn{
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .content-main-card {
            padding: 12px;
            border-radius: 8px;
            font-size: 12px;
            color: #333333;
            background: rgb(242, 246, 252);
            font-weight: 500;
        }
        .content-main-value{
            font-size: 18px;
            font-weight: 700;
            padding: 0 10px;
            color: #FE7B11;
        }
    }
}
    
</style>