<template>
    <div>
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsc">
                <span class="a-c-master a-fw-700 a-fs-16">抄表电量/电费（单位：{{ dataType == 1?'kwh':'元' }}）</span>
                <el-tooltip placement="top">
                    <div slot="content">
                        估算电量/电费：充电设备统计的用电量，电量*当日单价计算电费；仅统计创建了电费项目，且由我方缴纳的电费<br />
                        抄表电量/电费：电费账单上记录的已抄表电量；并将电费账单金额平均到抄表周期内得到日期的电费
                    </div>
                    <i class="el-icon-question a-c-blue" ></i>
                </el-tooltip>
            </div>
            <div class="a-flex-rfsc">
                <el-select v-model="dataType" @change="dataTypeChange">
                    <el-option label="电量维度" :value="1"></el-option>
                    <el-option label="电费维度" :value="2"></el-option>
                </el-select>
                <jd-other-date-range
                    ref="dateRange"
                    style="padding: 0 !important; margin-left: 20px"
                    @change="dateChange"
                    :pickerOptions="pickerOptions" 
                    :timeType.sync="timeType"
                    valueFormat="yyyy-MM-dd HH:mm:ss"
                    :minDate.sync="startDate" 
                    :maxDate.sync="endDate"
                    thisMonth>
                </jd-other-date-range>
            </div>
        </div>
        <div class="a-flex-rfsc a-mt-10">
            <div class="a-flex-cfsfs" style="margin-right:107px" v-if="dataType == 2">
                <span class="a-fs-14 a-c-normal">搜索范围内预估电费</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ util.numFormat(predictPay || 0) }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs" style="margin-right:107px" v-if="dataType == 2">
                <span class="a-fs-14 a-c-normal">搜索范围内抄表电费</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ util.numFormat(realPay || 0) }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs" style="margin-right:107px" v-if="dataType == 1">
                <span class="a-fs-14 a-c-normal">搜索范围内预估电量</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ predictEnergy }}</span>
                    <span class="a-fs-24 a-c-master">kwh</span>
                </div>
            </div>
            <div class="a-flex-cfsfs" style="margin-right:107px" v-if="dataType == 1">
                <span class="a-fs-14 a-c-normal">搜索范围内抄表电量</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-32 a-c-master a-fw-700">{{ realEnergy }}</span>
                    <span class="a-fs-24 a-c-master">kwh</span>
                </div>
            </div>
        </div>
        <div id="actualElectric" class="mychart " v-loading='showLoading'></div>
    </div>
</template>

<script>
    import util from '@/utils/util'
    export default {
        data () {
            return {
                util,
                myChartContxt: null,
                choiceDate: '',
                choiceMonth: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        this.choiceMonth = minDate
                        if (maxDate) {
                            this.choiceDate = ''
                            this.choiceMonth = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            if(this.timeType == 0){
                                let one = 31 * 24 * 3600 * 1000
                                const minTime = this.choiceDate - one
                                const maxTime = this.choiceDate + one
                                return time.getTime() < minTime || time.getTime() > maxTime
                            }else if(this.timeType == 1){
                                let minMonth = this.choiceMonth.getMonth(),
                                lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                                nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                                return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                            }
                        }else{
                            return ''
                        }
                    },
                },
                dataType: 2,
                timeType: 1,
                startDate: '',
                endDate: '',
                "predictEnergy": 0,
				"realEnergy": 0,
				"predictPay": 0,
				"realPay": 0,
                showLoading: false
            }
        },
        created () {
            this.startDate = this.$getDay.getSomeMonthDays(this.$getDay.getTodayBeforeMonth(11)).starttime + ' 00:00:00'
			this.endDate = this.$getDay.getSomeMonthDays(new Date()).endtime + ' 23:59:59'
        },
        mounted () {
            this.$refs['dateRange'].type = 'monthrange'
            this.getChartData()
        },
        props: {
            params: {
                default: ''
            }
        },
        watch:{
            params:{
                deep: true,
                handler (val) {
                    this.getChartData()
                }
            },
        },
        methods:{
            drawChart (xAxis=[], seriesData1=[], seriesData2=[]) { 
                var chartDom = document.getElementById('actualElectric');
                var option;
                option = {
                    color: ['#FFA900', '#6699FF', '#FF3030', '#FF9C00', '#FFD700','#3ba272'],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: (value, index)=> {
                                if(this.timeType==0){
                                    let arr = value.split('-')
                                    return arr[1]+'-'+arr[2];
                                }
                                return value
                            }
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 'dataMax',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                        axisLabel: {
                            formatter: (value, index)=>{
                                if(value > 10000) {
                                    return Number((value / 10000).toFixed(2)) + '万'
                                }else {
                                    return value
                                }
                            }
                        }
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            startValue: 0,
                            endValue: 100
                        },
                    ],
                    series: [
                        {   
                            name: this.dataType == 1?'估算电量':'估算电费',
                            data: seriesData1,
                            type: 'bar',
                            barMaxWidth: 20,
                            label: {
                                normal:{
                                    show:true,
                                    position: 'top',
                                    textStyle:{
                                        color: '#606366',
                                    }
                                }
                            },
                            itemStyle: {
                                normal:{
                                    barBorderRadius: [4,4,0,0],
                                }
                            },
                        },
                        {
                            name: this.dataType == 1?'抄表电量':'抄表电费',
                            data: seriesData2,
                            type: 'bar',
                            barMaxWidth: 20,
                            label: {
                                normal:{
                                    show:true,
                                    position: 'top',
                                    textStyle:{
                                        color: '#606366',
                                    }
                                }
                            },
                            itemStyle: {
                                normal:{
                                    barBorderRadius: [4,4,0,0],
                                }
                            },
                        }
                    ]
                };
                if(!this.myChartContxt){
                    this.myChartContxt = this.$echarts.init(chartDom);
                }
                this.myChartContxt.clear()
                this.myChartContxt.setOption(option,true);
            },
            dateChange () {
                this.getChartData()
            },
            dataTypeChange () {
                this.getChartData()
            },
            // 获取图表数据
            getChartData () {
                this.showLoading = true
                this.$Axios._post({
                    url: this.$Config.apiUrl.eleEnergy,
                    method: "post",
                    showLoading: false,
                    params: {
                        queryType: this.timeType,
						startDate: this.startDate,
						endDate: this.endDate,
						...this.reqData
                    }
                }).then(({data}) => {
                    this.showLoading = false
                    this.predictEnergy = data.predictEnergy
                    this.realEnergy = data.realEnergy
                    this.predictPay = data.predictPay
                    this.realPay = data.realPay

                    let dateArr = []
                    if(this.timeType == 0) {
                        dateArr = this.$getDay.enumerateDaysBetweenDates(this.startDate,this.endDate).map(item=>{
                            return {
                                day: item,
                                predictEnergy: 0, //预付电量
                                realEnergy: 0,//实付电量
                                predictPay: 0,//预付金额
                                realPay: 0,//实付金额
                            }
                        })
                    }else if(this.timeType == 1) {
                        dateArr = this.$getDay.enumerateMonthsBetweenDates(this.startDate,this.endDate).map(item=>{
                            return {
                                day: item,
                                predictEnergy: 0, //预付电量
                                realEnergy: 0,//实付电量
                                predictPay: 0,//预付金额
                                realPay: 0,//实付金额
                            }
                        })
                    }
                            
                    data.list.map(item=>{
                        let targetIndex = dateArr.findIndex(res=>{
                            return res.day == item.date
                        })
                        if(targetIndex != -1) {
                            dateArr[targetIndex].predictEnergy = item.predictEnergy
                            dateArr[targetIndex].realEnergy = item.realEnergy
                            dateArr[targetIndex].predictPay = Number((item.predictPay / 100).toFixed(2))
                            dateArr[targetIndex].realPay = Number((item.realPay / 100).toFixed(2))
                        }
                    })

                    let xAxis = []
                    let seriesData1 = []
                    let seriesData2 = []
                    let seriesData3 = []
                    let seriesData4 = []
                    dateArr.map(item=>{
                        xAxis.push(item.day)
                        seriesData1.push(item.predictEnergy || 0)
                        seriesData2.push(item.realEnergy || 0)
                        seriesData3.push(item.predictPay || 0)
                        seriesData4.push(item.realPay || 0)
                    })
                    
                    if(this.dataType == 1) {
                        this.drawChart(xAxis, seriesData1, seriesData2)
                    }else {
                        this.drawChart(xAxis, seriesData3, seriesData4)
                    }
                }).catch(_=>{
                    this.showLoading = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.tips2{
    width: 11px;
    height: 11px;
    background: #FFA900;
    border-radius: 3px;
}
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
.tooltips{
    background: #FFFFFF;
    box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);
    opacity: 0.96;
}
.tooltipsTitle{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606366;
    line-height: 27px;
    padding: 0 25px;
}
.tooltipsContent{
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    line-height: 32px;
}
</style>